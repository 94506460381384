<template>
  <div
    @click="clickEvent(event)"
    class="flex items-center cursor-pointer"
    v-tooltip="
      `
    <div class='w-full flex justify-between mb-2'>
        <span class='font-EffraR text-black text-lg mr-5'
        ><i class='far fa-clock'></i>
        ${_moment()(event.startAt).format('HH:mm')}-${_moment()(
        event.endAt
      ).format('HH:mm')}</span
        >
        <span class='font-EffraR text-shadow' style='color: ${event.category_color ||
          '#000'};'>
        <i class='fas fa-stethoscope'></i>
        ${event.category_title || ''}
        </span>
        </div>

        <div class='w-full flex justify-between items-center gap-x-6'>
          <span class='text-left text-black font-EffraR text-lg truncate'>
            ${event.patient_firstName || 'Créneau'} ${event.patient_lastName ||
        'réservé'}
          </span>


          <span class='font-EffraR text-shadow text-black'>
            Statut : ${$statusConsultation(event.state) || '--'}
          </span>
        </div>
    `
    "
  >
    <div
      :style="`background: ${event.category_color || '#000'};`"
      class="h-2 w-2 mr-2 rounded-full"
    ></div>

    <div class="flex justify-between items-center gap-x-1 flex-1 truncate">
      <span class="font-EffraR text-base truncate flex-1">
        {{
          `${event.patient_firstName || "Créneau"} ${event.patient_lastName ||
            "réservé"}`
        }}
      </span>

      <strong>{{ _moment()(event.startAt).format("HH:mm") }}</strong>

      <span class="text-sm font-EffraL font-semibold">
        {{ event.category_duration }}M
      </span>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import moment from "moment";

export default {
  props: {
    event: Object,
    startDrag: Function
  },
  methods: {
    _moment() {
      return moment;
    },
    clickEvent(data) {
      // //console.log(data);
      EventBus.$emit("CLICK_EVENT", data);
    }
  }
};
</script>
